/**
 * @flow
 */

import { ToolTips as AnnualVacationToolTips } from "../../../annual_vacation/webpack/shared/tooltips";

export const UserToolTips: {|
  employeeFullTime: string,
  employeeWaivedWeekend: string,
  employeeMaxConsecutiveShifts: string,
  acceptingCalls: string,
  acceptingTexts: string,
|} = {
  employeeFullTime: window.gettext("Employee Is Full-Time"),
  employeeWaivedWeekend: window.gettext("Waived 3rd/4th Weekend"),
  employeeMaxConsecutiveShifts: window.gettext("Max Consecutive Shifts"),
  acceptingCalls: window.gettext("Accepting Calls"),
  acceptingTexts: window.gettext("Accepting Text Messages"),
};

export const PrebookToolTips: {|
  calendarCannotApply: string,
  clickToProcessShift: string,
  clickToProcessSubmission: string,
  partialEligible: string,
|} = {
  calendarCannotApply: window.gettext("Cannot Apply – Click for more information."),
  clickToProcessShift: window.gettext("Click an ID to process all submissions for the shift/block."),
  clickToProcessSubmission: window.gettext("Click an employee ID to process that employee's submissions."),
  partialEligible: window.gettext("This submittable accepts partial shift submissions."),
};

export const MiscToolTips: {|
  debug: string,
  error: string,
  info: string,
  viewEmployee: string,
  viewSchedule: string,
  warn: string,
|} = {
  viewSchedule: window.gettext("View Schedule"),
  viewEmployee: window.gettext("View Employee"),
  debug: window.gettext("DEBUG"),
  info: window.gettext("INFO"),
  warn: window.gettext("WARN"),
  error: window.gettext("ERROR"),
};

export const SchedToolTips: {|
  payrollCodePremium: string,
  payrollCodeUnplanned: string,
  scheduleCount: string,
  scheduleUnfilled: string,
  viewGuidelines: string,
|} = {
  payrollCodeUnplanned: window.gettext("Unplanned"),
  payrollCodePremium: window.gettext("Premium"),
  scheduleUnfilled: window.gettext("Unfilled"),
  scheduleCount: window.gettext("Count"),
  viewGuidelines: window.gettext("View Guidelines"),
};

export const AllToolTips = {
  ...AnnualVacationToolTips,
  ...UserToolTips,
  ...PrebookToolTips,
  ...MiscToolTips,
  ...SchedToolTips,
};

export type AllToolTipKeys = $Keys<typeof AllToolTips>;
