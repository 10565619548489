/**
 * @flow
 */

import React from "react";
import ReactDOM from "react-dom";
import { ComponentWithIconAndMouseOverInfoBubble } from "../../shared/components/ComponentWithIconAndMouseOverInfoBubble";
import { type AllToolTipKeys, AllToolTips } from "../../shared/tooltips";
import { ComponentWithMouseOverInfoBubble } from "../../shared/components/ComponentWithMouseOverInfoBubble";
import type { FontAwesomeSolidIcon } from "../../shared/types/fontAwesomeTypes";
import { InternalOnlyError } from "../../shared/utils/errors";

/*
 * This class searches for all elements in the document that have the class name `react-info-tooltip` and will create a
 * tooltip that appears on hover of the children of the node that had the class name.
 *
 * In cases where elements with the class `react-info-tooltip` are created dynamically after the initial check is done,
 * the new elements will be missed. For templates where it is necessary to create tooltips for dynamic elements, the
 * root element must be tagged with `dynamic-tooltip-root`. When this is done, all changes to the DOM will be observed
 * and it will check through the new nodes for the `react-info-tooltip` class.
 */

const reactCollection: HTMLCollection<HTMLElement> = document.getElementsByClassName("react-info-tooltip");

/**
 * Creates an tooltip for all given elements.
 *
 * Tooltips can differ depending on the data each element has. Common tooltips may have a key to indicate what text it
 * should show on hover. Other tooltips will provide the exact text instead of a key (possibly due to showing model
 * data that it is impossible to make keys for). Some will have an info icon, and others will have no icon at all.
 *
 * @param collection A collection of all elements with the class `react-info-tooltip` that should have it's content
 * trigger a info bubble when scrolled over (or tapped on mobile).
 */
const createTooltipsFromElements = (collection: HTMLCollection<HTMLElement>) => {
  const reactContainers = [...Array(collection.length).keys()].map((i) => collection[i]);

  reactContainers.forEach((reactContainer) => {
    const data: {
      toolTipKey?: string,
      toolTipText?: string, // The tooltip in plain text. This is for when a key is not desired due to dynamic text.
      isIconOnLeft?: string,
      faIcon?: string,
      faIconColor?: string,
      shouldFollowCursor?: string,
    } = reactContainer.dataset;

    if (!data.toolTipText) {
      // Ignore empty string
      return;
    }

    if ((!data.toolTipText && !data.toolTipKey) || (data.toolTipText && data.toolTipKey)) {
      throw new InternalOnlyError("Either a key or text for the tooltip must be given. It must be one or the other.");
    }

    if (data.toolTipKey && !Object.keys(AllToolTips).includes(data.toolTipKey)) {
      throw new InternalOnlyError(`Invalid tool tip key. Valid keys are: ${Object.keys(AllToolTips).join(", ")}.`);
    }

    const isIconOnLeft = data.isIconOnLeft ? JSON.parse(data.isIconOnLeft) : false;
    const shouldFollowCursor = data.shouldFollowCursor ? JSON.parse(data.shouldFollowCursor) : true;
    // Flow can't figure it out but, after potentially throwing an error, we know this is a valid tooltip key.
    // As such, lets type-cast it to the appropriate type.
    let toolTipKey;
    if (data.toolTipKey) {
      toolTipKey = ((data.toolTipKey: any): AllToolTipKeys);
    }

    if (data.faIcon) {
      // Renders the tooltip with an icon that indicates it is a tooltip
      ReactDOM.render(
        <ComponentWithIconAndMouseOverInfoBubble
          toolTipKey={toolTipKey}
          toolTipNode={data.toolTipText}
          iconConfigOverride={{
            faIcon: ((data.faIcon: any): FontAwesomeSolidIcon),
            onLeft: isIconOnLeft,
            color: data.faIconColor,
          }}
          shouldFollowCursor={shouldFollowCursor}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: reactContainer.innerHTML }} />
        </ComponentWithIconAndMouseOverInfoBubble>,
        reactContainer
      );
    } else {
      // Renders the tooltip without an icon that indicates it is a tooltip
      ReactDOM.render(
        <ComponentWithMouseOverInfoBubble
          toolTipKey={toolTipKey}
          toolTipNode={data.toolTipText}
          shouldFollowCursor={shouldFollowCursor}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: reactContainer.innerHTML }} />
        </ComponentWithMouseOverInfoBubble>,
        reactContainer
      );
    }
  });
};

/**
 * Checks through all new DOM changes for additions that need a React tooltip (`react-info-tooltip`), and creates them.
 *
 * Creating these new tooltips will also trigger the observer and call this method again, but because the new elements
 * created are children of the div that specifies it should be a tooltip, and not the div itself, it will find no
 * new ones and only be triggered once.
 *
 * @param mutations - A record of all nodes added to and removed from the root node.
 */
function domHasChanged(mutations: MutationRecord[]) {
  mutations
    .filter((mutation) => mutation.addedNodes.length > 0)
    .map((mutation) => mutation.target)
    .filter((node) => node.nodeType === Node.ELEMENT_NODE)
    // $FlowExpectedError - We know this can be cast to an element because it is an element node, but flow doesn't
    .flatMap((node) => (node: Element).getElementsByClassName("react-info-tooltip"))
    .filter((elementCollection) => elementCollection.length > 0)
    .forEach((elementCollection) => createTooltipsFromElements(elementCollection));
}

/**
 * Creates a observer that will be triggered when DOM changes are made to a root node.
 *
 * The node being observed is specified by the class `dynamic-tooltip-root`. If no element exists with this class -
 * which should be the case if the template does not have tooltips being created after the initial check - then no
 * observer will be created. If more than one element with this class exists an error will be thrown.
 *
 * Whenever there are updates to the children of the given root, or the subtrees of these children, a callback function
 * that checks for new tooltips will be invoked.
 */
const createObserverForTooltipRelatedDomChanges = () => {
  const dynamicTarget = document.getElementsByClassName("dynamic-tooltip-root");
  if (dynamicTarget.length === 1) {
    const observer = new MutationObserver(domHasChanged);
    observer.observe(dynamicTarget[0], { attributes: false, childList: true, subtree: true });
  } else if (dynamicTarget.length > 1) {
    throw new InternalOnlyError("There can only be one observed root.");
  }
};

document.addEventListener("DOMContentLoaded", () => {
  if (reactCollection) {
    createTooltipsFromElements(reactCollection);
  }
  createObserverForTooltipRelatedDomChanges();
});
