/**
 * @flow
 */

import React, { type Element, type Node } from "react";
import type { FontAwesomeSolidIcon } from "../types/fontAwesomeTypes";
import type { AllToolTipKeys } from "../tooltips";

import { ComponentWithMouseOverInfoBubble } from "./ComponentWithMouseOverInfoBubble";
import { FontAwesomeIcon } from "./FontAwesomeIcon";
import type { ColorPropType } from "../types/styleTypes";

type IconConfiguration = {
  onLeft: boolean,
  color: ?ColorPropType,
  faIcon: FontAwesomeSolidIcon,
  size: number,
};

type IconConfigurationOverride = {
  onLeft?: boolean,
  color?: ?ColorPropType,
  faIcon?: FontAwesomeSolidIcon,
  size?: number,
};

type Props = {|
  children?: ?Node,
  toolTipKey?: AllToolTipKeys,
  toolTipNode?: Node,
  iconConfigOverride?: IconConfigurationOverride,
  shouldFollowCursor?: boolean,
|};

type ComponentWithInfoIconProps = {|
  children?: ?Node,
  iconConfig: IconConfiguration,
|};

type ToolTipIconProps = {|
  iconConfig: IconConfiguration,
|};

/**
 * Creates a component with a icon that will display an info bubble on mouse hover of it's child elements.
 *
 * The bubble will be displayed by click/tap instead of hover on mobile devices and when specified.
 *
 * @param children Hovering or clicking this node will show the info bubble. An icon indicating that this is a tooltip
 * will be present either to the left or right of this content.
 * @param toolTipKey A key indicating what text is used for the tool tip. Should be `null` if `toolTipNode` is present.
 * @param toolTipNode The node for the tool tip. Should be `null` if `toolTipKey` is present.
 * @param iconConfigOverride Params for tweaking the icon displayed
 * @param shouldFollowCursor Whether or not the info bubble should follow the cursor on hover or stick in one place.
 * @returns {ComponentWithIconAndMouseOverInfoBubble}
 */
export const ComponentWithIconAndMouseOverInfoBubble = ({
  children,
  toolTipKey,
  toolTipNode,
  iconConfigOverride,
  shouldFollowCursor = true,
}: Props): Node => {
  const defaultIconConfig: IconConfiguration = { onLeft: false, color: null, size: 17, faIcon: "info-circle" };
  const iconConfig: IconConfiguration = { ...defaultIconConfig, ...iconConfigOverride };

  return (
    <ComponentWithMouseOverInfoBubble
      toolTipKey={toolTipKey}
      toolTipNode={toolTipNode}
      shouldFollowCursor={shouldFollowCursor}
    >
      <ComponentWithIcon iconConfig={iconConfig}>{children}</ComponentWithIcon>
    </ComponentWithMouseOverInfoBubble>
  );
};

export const ComponentWithIcon = (props: ComponentWithInfoIconProps): Element<"span"> => {
  const margin = props.iconConfig.faIcon ? props.iconConfig.size / 6 : 0;

  // Wrap everything in a div with 'display: flex' so we don't get full-width table cells accidentally.
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  };

  if (props.iconConfig.onLeft) {
    return (
      <span style={containerStyle}>
        <ToolTipIcon iconConfig={props.iconConfig} />
        <div style={{ maxWidth: "100%", marginLeft: margin }}>{props.children}</div>
      </span>
    );
  }
  return (
    <span style={containerStyle}>
      <div style={{ maxWidth: "100%", marginRight: margin }}>{props.children}</div>
      <ToolTipIcon iconConfig={props.iconConfig} />
    </span>
  );
};

export const ToolTipIcon = (props: ToolTipIconProps): Element<"div"> => {
  const style = props.iconConfig.color ? { color: props.iconConfig.color } : {};

  return (
    <div style={{ flexShrink: 0 }}>
      <FontAwesomeIcon icon={{ style: "solid", name: props.iconConfig.faIcon }} style={style} size="small" />
    </div>
  );
};
