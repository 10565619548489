/**
 * @flow
 */

import React from "react";
import type { Element, Node } from "react";
import Tippy from "@tippy.js/react";
import { followCursor, sticky } from "tippy.js";
import "tippy.js/dist/tippy.css";

import { useResponsiveBreakpoints } from "../hooks/useResponsiveBreakpoints";
import { AllToolTips } from "../tooltips";
import type { AllToolTipKeys } from "../tooltips";
import { InternalOnlyError } from "../utils/errors";

type Props = {|
  children?: ?Node,
  toolTipKey?: AllToolTipKeys,
  toolTipNode?: Node,
  shouldFollowCursor?: boolean,
|};

/**
 * Creates a component that will display an info bubble on mouse hover of it's child elements.
 *
 * The bubble will be displayed by click/tap instead of hover on mobile devices and when specified.
 *
 * @param children Hovering or clicking this node will show the info bubble.
 * @param toolTipKey A key indicating what text is used for the tool tip. Should be `null` if `toolTipNode` is present.
 * @param toolTipNode A react node to render as the tooltip. Should be `null` if `toolTipKey` is present.
 * @param shouldFollowCursor Whether or not the info bubble should follow the cursor on hover or stick in one place.
 * @returns {ComponentWithMouseOverInfoBubble}
 */
export const ComponentWithMouseOverInfoBubble = ({
  children,
  toolTipKey,
  toolTipNode,
  shouldFollowCursor = true,
}: Props): Element<"div"> => {
  const { responsiveBreakpoint } = useResponsiveBreakpoints();

  if ((!toolTipNode && !toolTipKey) || (toolTipNode && toolTipKey)) {
    throw new InternalOnlyError("Either a key or text for the tooltip must be given. It must be one or the other.");
  }

  return (
    // Wrap everything in a div with  'display: inline-flex' so we don't get full-width table cells accidentally.
    // Full-width table cells can awkwardly make the tooltip trigger when the cursor is far from the actual content.
    <div
      style={{
        display: "inline-flex",
      }}
    >
      <Tippy
        arrow
        // On mobile, if we open a tool tip, then scroll a containing scroll view, our tool tip position will become
        // incorrect. By specifying "sticky", the tool tip position will update appropriately.
        sticky
        // By default, this is "scrollParent". This prevents tippys form overflowing scroll views, which is awkward.
        // "viewport" will ensure the tool tip is always fully on screen, which isn't great either, because once you
        // completely scroll the tippy'd element off-screen, its weird the tippy sticks in the viewport. "window"
        // seems to be the best. If we notice any weird behaviour with window, don't change it unless that bad
        // behaviour is more-bad than the other described behaviours of the other options.
        boundary="window"
        followCursor={responsiveBreakpoint !== "mobile" && shouldFollowCursor}
        delay={[50, 20]}
        duration={[100, 100]}
        content={toolTipKey ? AllToolTips[toolTipKey] : toolTipNode}
        plugins={[followCursor, sticky]}
      >
        <span>{children}</span>
      </Tippy>
    </div>
  );
};
