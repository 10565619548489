/**
 * @flow
 */

import { useLayoutEffect, useState } from "react";
import { useWindowWidth } from "./useWindowWidth";

// This logic is meant to mimic the sass mixin logic defined here:
// https://bulma.io/documentation/overview/responsiveness/
// Unfortunately if we override any of bulma's breakpoints, we'll have to duplicate that logic here.

const MOBILE_UP_TO_PX = 768;
const TABLET_ABOVE_PX = 769;
const DESKTOP_ABOVE_PX = 1024;
const WIDESCREEN_ABOVE_PX = 1216;
const FULL_HD_ABOVE_PX = 1408;
const QUAD_HD_ABOVE_PX = 1920;

export const isMobile = (width: number): boolean => width <= MOBILE_UP_TO_PX;
export const isAtLeastTablet = (width: number): boolean => width > TABLET_ABOVE_PX;
export const isAtLeastDesktop = (width: number): boolean => width > DESKTOP_ABOVE_PX;
export const isAtLeastWidescreen = (width: number): boolean => width > WIDESCREEN_ABOVE_PX;
export const isAtLeastFullHD = (width: number): boolean => width > FULL_HD_ABOVE_PX;
export const isAtLeastQuadHD = (width: number): boolean => width > QUAD_HD_ABOVE_PX;

export type ResponsiveBreakpoint = "mobile" | "tablet" | "desktop" | "widescreen" | "fullhd" | "quadhd";
type ReturnT = { responsiveBreakpoint: ResponsiveBreakpoint, windowWidth: number };

export function useResponsiveBreakpoints(): ReturnT {
  // eslint-disable-next-line i18next/no-literal-string
  const [responsiveBreakpoint, setResponsiveBreakpoint] = useState<ResponsiveBreakpoint>("desktop");
  const windowWidth = useWindowWidth();

  // We need a layout effect or the effect won't trigger a re-render when the breakpoint changes. This can mean that
  // the page won't re-layout until one render after the breakpoint changes, which can be janky.
  /* eslint-disable i18next/no-literal-string */
  useLayoutEffect(() => {
    if (isAtLeastQuadHD(windowWidth)) {
      setResponsiveBreakpoint("quadhd");
    } else if (isAtLeastFullHD(windowWidth)) {
      setResponsiveBreakpoint("fullhd");
    } else if (isAtLeastWidescreen(windowWidth)) {
      setResponsiveBreakpoint("widescreen");
    } else if (isAtLeastDesktop(windowWidth)) {
      setResponsiveBreakpoint("desktop");
    } else if (isAtLeastTablet(windowWidth)) {
      setResponsiveBreakpoint("tablet");
    } else if (isMobile(windowWidth)) {
      setResponsiveBreakpoint("mobile");
    }
  }, [windowWidth]);
  return { responsiveBreakpoint, windowWidth };
  /* eslint-enable i18next/no-literal-string */
}
